import React from 'react'
import BlogTemplate from './BlogTemplate'
import { graphql } from 'gatsby'
import Blog from './blog'

const BlogIndex = (props) => {
  if (!props.pageContext.slug) {
    return <Blog />
  } else {
    return <BlogTemplate data={props.data.allWpPost.nodes?.[0]} />
  }
}

export default BlogIndex

export const data = graphql`
  query ($slug: String) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        uri
        slug
        title
        date(formatString: "MMM DD, YYYY")
        blogPost {
          readingTimeMinutes
          tagList {
            tag
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
            email
            description
            avatar {
              url
            }
          }
        }
        content
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`
